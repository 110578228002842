const baseUrl = process.env.NEXT_PUBLIC_FRONTEND_API_BASE_URL
const gatewayBaseUrlOnboarding = process.env.NEXT_PUBLIC_GATEWAY_API_ONBOARDING_BASE_URL

const urls = {
  //Event AUDIT
  LOG_EVENT_AUDIT: baseUrl + '/event-audit/log',

  // SELF ONBOARDING
  SELF_ONBOARDING_SIGNUP: `${gatewayBaseUrlOnboarding}/cauth/user-info/signup`,
  SELF_ONBOARDING_LIGHT_SIGNUP: `${gatewayBaseUrlOnboarding}/cauth/user-info/light-signup`,
  CREATE_SMS_CODE: `${gatewayBaseUrlOnboarding}/cauth/user-info`,
  VALIDATE_PHONE: `${gatewayBaseUrlOnboarding}/cauth/user-info/validate-phone`,
  VALIDATE_EMAIL: `${gatewayBaseUrlOnboarding}/cauth/user-info/validate-email`,
  VALIDATE_REFERRAL_CODE: `${gatewayBaseUrlOnboarding}/cauth/referrals/validate?country=:country`,

  GET_LOCATION_IP: 'https://api.country.is/'
}

export default urls
