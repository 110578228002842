import { makeStyles } from 'tss-react/mui'

export default makeStyles()(() => ({
  inputTextOnFocus: {
    '& input': {
      color: '#9E9E9F',
      fontWeight: 'normal'
    },
    inputTextFocus: {
      '& input': {
        color: '#000000'
      }
    }
  }
}))
