import { backgroundColorField, ctaField, imageField, textField } from '@/defaultSchema/fields'
import { defineType } from 'sanity'

export default defineType({
  type: 'object',
  name: 'banner',
  title: 'Banner',
  groups: [
    {
      name: 'images',
      title: 'Images'
    }
  ],
  initialValue: {
    fullHeight: false,
    withSignupForm: false,
    positionTitle: 'left'
  },
  fields: [
    textField(),
    {
      type: 'string',
      name: 'cta',
      title: '(Deprecated) CTA',
      description: '(Deprecated) The Call to action <a>{cta}</a>'
    },
    {
      type: 'string',
      name: 'linkCTA',
      title: '(Deprecated) Link CTA',
      description: '(Deprecated) The Call to action <a href={linkCTA}>',
      hidden: ({ parent }) => !parent?.cta
    },
    ctaField({ name: 'ctaV2' }),
    {
      type: 'string',
      name: 'positionTitle',
      title: 'Position title',
      initialValue: 'top',
      options: {
        list: [
          { title: 'Left', value: 'left' },
          { title: 'Right', value: 'right' }
        ]
      },
      description: 'The position title & subtitle',
      validation: (Rule) => Rule.required()
    },
    {
      type: 'boolean',
      name: 'fullHeight',
      title: 'full height',
      description: 'Select true if you want the banner to be 100% of the screen height'
    },
    {
      type: 'boolean',
      name: 'withSignupForm',
      title: 'Signup Form',
      description: 'Select true if you want to include the registration form in this banner.'
    },
    backgroundColorField,
    {
      type: 'file',
      title: 'Video',
      name: 'videoFile',
      options: {
        accept: 'video/mp4, video/webm'
      },
      fields: [
        {
          type: 'string',
          name: 'alt',
          title: 'Alt'
        },
        {
          type: 'boolean',
          name: 'loop',
          title: 'Loop',
          initialValue: false
        }
      ],
      hidden: ({ parent }) => parent?.withSignupForm
    },
    imageField(),
    {
      type: 'image',
      name: 'backgroundImage',
      group: 'images',
      title: 'Background Image css',
      options: {
        hotspot: true
      },
      fields: [
        {
          type: 'image',
          name: 'backgroundImageMobile',
          group: 'images',
          title: 'Background Image mobile css',
          options: {
            hotspot: true
          }
        }
      ]
    }
  ],
  preview: {
    select: {
      backgroundImage: 'backgroundImage',
      image: 'image'
    },
    prepare({ backgroundImage, image }) {
      return {
        media: backgroundImage || image,
        title: 'Banner'
      }
    }
  }
})
