import { makeStyles } from 'tss-react/mui'

export default makeStyles()((theme, { backgroundColor, position }) => ({
  container: {
    padding: '40px 24px',
    background: backgroundColor ? backgroundColor?.hex : '#FAFAFA',
    [theme.breakpoints.up('md')]: {
      padding: '60px 50px',
      display: position
    }
  },
  gridContainer: {
    padding: 35,
    margin: '0 auto',
    maxWidth: 1280,
    backgroundColor: backgroundColor ? backgroundColor?.hex : '#FAFAFA'
  },
  containerTitles: {
    display: 'grid',
    paddingBottom: 40,
    width: '100%'
  },
  cta: {
    paddingTop: 35
  },
  grids: {
    display: 'flex'
  }
}))
