import { Typography } from '@mui/material'
import { makeStyles } from 'tss-react/mui'
import { FormattedMessage } from 'react-intl'

const useStyles = makeStyles()(() => ({
  errorMessage: {
    color: '#ff441f',
    fontSize: '0.75rem',
    marginLeft: '0.5rem'
  }
}))

export default function ErrorMessage({ show, id, values }) {
  const { classes } = useStyles()

  if (show) {
    return (
      <Typography className={classes.errorMessage}>
        <FormattedMessage id={id} values={values} />
      </Typography>
    )
  }

  return null
}
