export const GTM_EVENTS = {
  hotjarIdentify: 'hotjar-identify',
  hotjarTag: 'hotjar-tag',
  singUp: 'sign-up',
  lightSignup: 'light-signup',
  verificationCode: 'verification-code',
  basicInformation: 'basic-information',
  menuType: 'menu-selection',
  documentUpload: 'doc_upload',
  r2s: 'r2s',
  menuSuccessfullyUploaded: 'menu-successfully-uploaded',

  lightSignupSent: 'light_signup_sent',
  lightSignupSuccess: 'light_signup_success',
  lightSignupError: 'light_signup_error',

  signupFormSuccess: 'signup_form_success',
  signupVerificationCodeSent: 'signup_verification_code_sent',
  signupVerificationCodeSuccess: 'signup_verification_code_success',
  signupVerificationCodeError: 'signup_verification_code_error',

  basicInformationSent: 'zoho_lead_sent',
  basicInformationSuccess: 'zoho_lead_success',
  basicInformationError: 'zoho_lead_error',

  deliveryTypeSent: 'delivery_type_sent',
  deliveryTypeSuccess: 'delivery_type_success',
  deliveryTypeError: 'delivery_type_error',

  bankAccountSent: 'bank_account_sent',
  bankAccountSuccess: 'bank_account_success',
  bankAccountError: 'bank_account_error',

  menuSelect: 'menu_select',
  menuProSent: 'menu_pro_sent',
  menuProSuccess: 'menu_pro_success',
  menuProError: 'menu_pro_error',
  menuFreeSent: 'menu_free_sent',
  menuFreeSuccess: 'menu_free_success',
  menuFreeError: 'menu_free_error',
  scrapperSent: 'scrapper_sent',
  scrapperSuccess: 'scrapper_success',
  scrapperError: 'scrapper_error',

  logoCoverSent: 'logo_cover_sent',
  logoCoverSuccess: 'logo_cover_success',
  logoCoverError: 'logo_cover_error',

  schedulesSent: 'schedules_sent',
  schedulesSuccess: 'schedules_success',
  schedulesError: 'schedules_error',

  documentsSent: 'documents_sent',
  documentsSucess: 'documents_success',
  documentsError: 'documents_error',

  menuSimple: 'menu_simple',
  menuManual: 'menu_manual',
  menuLink: 'menu_link',

  logo: 'click_logo',
  cover: 'click_cover',

  documentIdentification: 'documentIdentification'
}

export const HOTJAR_PAGE_TAGS = {
  signup: 'Signup',
  storeInfo: 'Info basica',
  deliveryType: 'Tipo de delivery',
  bankAccount: 'Cuenta bancaria',
  menuOptions: 'Menu options',
  menuFree: 'Menu Free',
  menuPro: 'Menu Pro',
  menuScraping: 'Menu Scraping',
  homeAndLogo: 'Home y logo',
  schedules: 'Horarios',
  documents: 'Documentos',
  summary: 'Resumen'
}
