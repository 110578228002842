import { defineType } from 'sanity'
import { MasterDetailIcon } from '@sanity/icons'
import { backgroundColorField, textField } from '@/defaultSchema/fields'

export default defineType({
  type: 'object',
  title: 'Testimonial',
  name: 'sectionCardsTestimonial',
  fields: [
    textField(),
    backgroundColorField,
    {
      type: 'array',
      name: 'cards',
      title: 'Cards',
      description: 'Cards in the section',
      validation: (Rule) => Rule.required().min(1).max(3),
      of: [
        {
          type: 'object',
          name: 'cards',
          fields: [
            backgroundColorField,
            {
              type: 'string',
              name: 'txtButton',
              title: 'Text button',
              description: 'The text <button>{text}</button>',
              validation: (Rule) => Rule.required()
            },
            {
              type: 'text',
              name: 'testimonial',
              title: 'Testimonial',
              description: 'The testimonial <p>{testimonial}</p>',
              validation: (Rule) => Rule.required()
            },
            {
              type: 'string',
              name: 'txtBy',
              title: 'Text by',
              description: 'The text by <p>{txtBy}</p>'
            },
            {
              type: 'image',
              name: 'logoRestaurant',
              title: 'Logo restaurant',
              description: '<img>',
              options: {
                hotspot: true
              },
              fields: [
                {
                  name: 'alt',
                  type: 'string',
                  title: 'Alt',
                  validation: (Rule) => Rule.required()
                }
              ]
            },
            {
              type: 'string',
              name: 'nameRestaurant',
              title: 'Name restaurant',
              description: 'The name restaurant <p>{nameRestaurant}</p>'
            }
          ]
        }
      ]
    }
  ],
  preview: {
    select: {
      title: 'cards.txtButton'
    },
    prepare({ title }) {
      return {
        title: 'Section Cards Testimonial',
        subtitle: title,
        icon: MasterDetailIcon
      }
    }
  }
})
