import { Card, Grid, Button } from '@mui/material'
import Image from 'next/image'
import urlFor from '@/lib/urlFor'
import { getTextColor } from '@/utils/getTextColor'

import useStyles from './SectionGrandPartners.styles'
import Text from '../text/Text'
import Link from 'next/link'
import CallToAction from '../call-to-action/CallToAction'

export const SectionGranPartners = ({ text, backgroundColor, logoParnerts, cta }) => {
  const textColor = getTextColor(backgroundColor ? backgroundColor.hex : '#FAFAFA')
  const { classes } = useStyles({ backgroundColor: backgroundColor, textColor: textColor })

  return (
    <Grid
      container
      component='section'
      direction='row'
      justifyContent='center'
      alignItems='center'
      className={classes.container}
    >
      <Grid maxWidth={1280} margin={'0 auto'}>
        {text && (
          <Grid display='grid' gap='20px'>
            <Text blockContent={text} textColor={textColor} />
          </Grid>
        )}

        <Grid direction='row' justifyContent='center' alignItems='center' className={classes.gridImg}>
          {logoParnerts?.map((logo, i) => {
            const partnerLogo = logo?.logoPartner
            const imageUrl = urlFor(partnerLogo)

            return (
              <div key={i} className={classes.image}>
                {logo.external ? (
                  <Link href={`${logo.external}`} target='_blank'>
                    <Image src={imageUrl} alt={logo?.altLogo} width={150} height={53} objectFit='contain' />
                  </Link>
                ) : (
                  <Image
                    src={imageUrl}
                    alt={logo?.altLogo}
                    width={200}
                    height={62}
                    layout='responsive'
                    objectFit='contain'
                  />
                )}
              </div>
            )
          })}
        </Grid>

        {cta?.label && (
          <Grid direction='row' justifyContent='center' alignItems='center' sx={{ pt: 4 }}>
            <CallToAction {...cta} />
          </Grid>
        )}
      </Grid>
    </Grid>
  )
}

export default SectionGranPartners
