import Link from 'next/link'
import { Typography, Grid, Box, useMediaQuery } from '@mui/material'
import { FormattedMessage } from 'react-intl'

import CreateAccountForm from './CreateAccount.form'
import useStyles from './CreateAccount.styled'

const CreateAccount = ({ onSubmitSuccess, setSnackBarOpen, setSnackBarMsg }) => {
  const { classes } = useStyles()
  const isTablet = useMediaQuery((theme) => theme.breakpoints.up('md'))

  return (
    <Box>
      <Grid>
        <Typography variant='h3' className={classes.createAccountTitle}>
          <FormattedMessage id={'selfOnboardingPage.landingPage.hero.createAccountForm.title'} />
        </Typography>
        <Box
          display='grid'
          gridTemplateColumns={isTablet ? 'repeat(2, max-content)' : 'max-content'}
          justifyContent='center'
          gap={isTablet ? '0.4em' : '0'}
        >
          <Typography variant='body1' className={classes.createAccountSubtitle}>
            <FormattedMessage id='selfOnboardingPage.landingPage.hero.createAccountForm.subtitle' />
          </Typography>
          <Link href={`${process.env.NEXT_PUBLIC_URL_PARTNERS}/login`}>
            <Typography className={classes.createAccountLink}>
              <FormattedMessage id='selfOnboardingPage.landingPage.hero.createAccountForm.subtitle.strong' />
            </Typography>
          </Link>
        </Box>
      </Grid>
      <CreateAccountForm
        onSubmitSuccess={onSubmitSuccess}
        setSnackBarOpen={setSnackBarOpen}
        setSnackBarMsg={setSnackBarMsg}
      />
    </Box>
  )
}

export default CreateAccount