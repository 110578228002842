import { TableCell, Table, TableHead, TableRow, Grid, TableBody, Typography } from '@mui/material'

import useStyles from './TableSimple.styles'

const TableSimple = ({ sizeTable }) => {
  const { classes } = useStyles()

  return (
    <Grid container direction='row' justifyContent='left' alignItems='center'>
      <Table fixedHeader={false} style={{ tableLayout: 'auto' }}>
        <TableHead>
          <TableRow className={classes.tableHead}>
            {sizeTable?.rows[0]?.cells.map((content, i) => (
              <TableCell key={i} sx={{ width: content == '#' ? 10 : 'auto' }} className={classes.tableHead}>
                {content}
              </TableCell>
            ))}
          </TableRow>
        </TableHead>

        <TableBody>
          {sizeTable?.rows
            ?.filter((row, i) => i !== 0)
            .map((row, i) => (
              <TableRow key={i}>
                {row.cells.map((content, j) => (
                  <TableCell key={j}>{content}</TableCell>
                ))}
              </TableRow>
            ))}
        </TableBody>
      </Table>
    </Grid>
  )
}

export default TableSimple
