import { makeStyles } from 'tss-react/mui'

export default makeStyles()((theme) => ({
  content: {
    display: 'flex',
    flexDirection: 'row',
    flexWrap: 'nowrap',
    justifyContent: 'space-between',
    fontFamily: 'Poppins, sans-serif !important'
  },
  success: {
    backgroundColor: '#2ec4b6 !important',
    borderColor: '#2ec4b6 !important'
  },
  error: {
    backgroundColor: '#f33959 !important',
    borderColor: '#f33959 !important'
  },
  info: {
    backgroundColor: '#5867c3 !important',
    borderColor: '#5867c3 !important'
  },
  warning: {
    backgroundColor: '#ffaa00 !important',
    borderColor: '#ffaa00 !important'
  },
  multiline: {
    alignItems: 'flex-start',
    backgroundColor: '#ffffff',
    borderLeft: '3px solid',
    boxShadow: '0 10px 20px 0 rgba(0, 0, 0, 0.2)',
    color: '#1f2532'
  },
  icon: {
    fontSize: 20
  },
  iconVariant: {
    opacity: 0.9,
    marginRight: 20
  },
  textContainer: {
    display: 'flex',
    flexDirection: 'column'
  },
  title: {
    fontWeight: 'bold',
    padding: '3px 0 6px'
  },
  message: {
    alignItems: 'center',
    display: 'flex',
    [theme.breakpoints.up('sm')]: {
      maxWidth: 300
    }
  }
}))
