import React from 'react'
import { IconButton, Snackbar, SnackbarContent } from '@mui/material'
import {
  CheckCircle,
  Error,
  Info,
  Close,
  Cancel,
  Warning,
  InfoOutlined,
  ErrorOutlineOutlined,
  CheckCircleOutlineOutlined
} from '@mui/icons-material'

import useStyles from './CustomSnackbar.styles'

const variantIcon = {
  success: CheckCircle,
  warning: Warning,
  error: Error,
  info: Info
}

const variantOutlinedIcon = {
  success: CheckCircleOutlineOutlined,
  warning: Warning,
  error: ErrorOutlineOutlined,
  info: InfoOutlined
}

export default function CustomSnackbar({
  open = false,
  className = '',
  title = null,
  message = null,
  onClose,
  variant = 'info',
  showIcon = true,
  pureMessage,
  outlined = false,
  cancelOutlined = true
}) {
  const { classes, cx } = useStyles()

  const Icon = () => {
    if (variant) {
      const IconComp = outlined ? variantOutlinedIcon[variant] : variantIcon[variant]
      return <IconComp className={cx(classes.icon, classes.iconVariant)} />
    } else {
      return null
    }
  }

  return (
    <Snackbar anchorOrigin={{ vertical: 'top', horizontal: 'right' }} open={open} onClose={onClose}>
      <SnackbarContent
        className={cx(classes.content, classes[variant], className, {
          [classes.multiline]: title
        })}
        aria-describedby='snackbar-content'
        message={
          <span id='snackbar-content' className={classes.message}>
            {showIcon && <Icon />}
            <span className={classes.textContainer}>
              {title && <span className={classes.title}>{title}</span>}
              {message && <span dangerouslySetInnerHTML={{ __html: message }} />}
              {pureMessage && pureMessage}
            </span>
          </span>
        }
        action={[
          <IconButton key='close' aria-label='Close' color='inherit' className={classes.close} onClick={onClose}>
            {cancelOutlined ? <Close className={classes.icon} /> : <Cancel className={classes.icon} />}
          </IconButton>
        ]}
      />
    </Snackbar>
  )
}
