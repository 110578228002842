import { Card, CardContent, CardMedia, Grid } from '@mui/material'
import Image from 'next/image'
import urlFor from '@/lib/urlFor'

import useStyles from './CardImageTop.styles'
import Text from '../text/Text'
import CallToAction from '../call-to-action/CallToAction'

const CardImageTop = ({ image, text, cta, icon, backgroundColor, borderColor, withShadow }) => {
  const imageUrl = urlFor(image)

  const { classes } = useStyles({
    icon,
    backgroundColor,
    borderColor,
    withShadow,
    contain: image?.containSize
  })

  return (
    <Grid item md={3} sm={12}>
      <Card className={classes.card} sx={{ height: '100%' }}>
        {imageUrl &&
          (icon ? (
            <Grid container justifyContent='center'>
              <Image src={imageUrl} alt={image.alt} width={80} height={80} className={classes.iconImage} />
            </Grid>
          ) : (
            <CardMedia sx={{ height: 220 }} image={imageUrl} alt={image.alt} className={classes.imgCard} />
          ))}

        <CardContent>
          <Text blockContent={text} />
        </CardContent>

        <CallToAction {...cta} />
      </Card>
    </Grid>
  )
}

export default CardImageTop
