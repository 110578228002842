import { width } from '@mui/system'
import { makeStyles } from 'tss-react/mui'

export default makeStyles()((theme) => ({
  container: {
    padding: '0px 10px',
    background: '#FAFAFA',
    textAlign: 'center',
    [theme.breakpoints.up('md')]: {
      padding: '0px 50px'
    },
    width: '100%'
  },
  tableHead: {
    background: '#FBE9E6',
    color: '#FF4940',
    fontWeight: 600
  },
  tableRow: {
    display: 'contents'
  }
}))
