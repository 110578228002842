import { phonePrefixes } from '../constants/PhoneLenghts'

export const findPrefixSelect = (phone) => {
  const select = phonePrefixes.find((countryPrefix) => phone?.includes(countryPrefix.value))

  return select ? `${select?.value}|${select?.country}` : null
}

export const replacePrefix = (phone) => {
  const select = phonePrefixes.find((countryPrefix) => phone?.includes(countryPrefix.value))

  return select ? phone?.replace(select.value, '') : null
}

export const phoneRemoveCharacter = (phone, remove) => {
  return phone.slice(remove)
}
