import { userInformation } from '@/components/interfaces/signup/userInformation.interface'
import BaseService from './BaseService'

class SelfOnboardingService extends BaseService {
  createSmsCode(userInfo: userInformation) {
    return this.getServiceInstance().post(this.urls.CREATE_SMS_CODE, {
      country: userInfo.country,
      email: userInfo.email,
      phone_number: userInfo.phone,
      source: userInfo.source,
      campaign: userInfo.campaign,
      medium: userInfo.campaign,
      isCcp: userInfo.isCcp,
      referralCode: userInfo.referralCode
    })
  }

  validateCodePhone(userInfo: userInformation) {
    return this.getServiceInstance()
      .post(this.urls.VALIDATE_PHONE, {
        country: userInfo.country,
        email: userInfo.email,
        phone_number: userInfo.phone,
        access_code_phone: userInfo.accessCodePhone
      })
      .then((resp) => resp.data.valid)
  }

  validateCodeEmail(userInfo: userInformation) {
    return this.getServiceInstance()
      .post(this.urls.VALIDATE_EMAIL, {
        country: userInfo.country,
        email: userInfo.email,
        phone_number: userInfo.phone,
        access_code_email: userInfo.accessCodeEmail,
        access_code_phone: userInfo.accessCodePhone
      })
      .then((res) => res.data.valid)
  }

  signup(userSignup: userInformation) {
    return this.getServiceInstance().post(this.urls.SELF_ONBOARDING_SIGNUP, {
      email: userSignup.email,
      name: userSignup.name,
      lastname: userSignup.lastName,
      password: userSignup.password,
      phone_number: userSignup.phone,
      country_id: userSignup.country,
      access_code_phone: userSignup.accessCodePhone,
      access_code_email: userSignup.accessCodeEmail
    })
  }

  lightSignup({
    email,
    name,
    phone,
    password,
    lastName,
    country,
    source,
    campaign,
    medium,
    brandId,
    storeId,
    isCcp,
    referralCode
  }) {
    return this.getServiceInstance().post(this.urls.SELF_ONBOARDING_LIGHT_SIGNUP, {
      email,
      name,
      phone_number: phone,
      password,
      lastname: lastName,
      country,
      source,
      campaign,
      medium,
      brandId,
      storeId,
      isCcp,
      referralCode
    })
  }

  validateReferralCode(body) {
    const url = this.replaceUrlParams(this.urls.VALIDATE_REFERRAL_CODE, body)

    return this.getServiceInstance()
      .post(url, body)
      .then((res) => res.data)
  }
}

export default SelfOnboardingService
