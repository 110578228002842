import { makeStyles } from 'tss-react/mui'

export default makeStyles()((theme, { colorText, backgroundColor }) => ({
  card: {
    maxHeight: '100%',
    borderRadius: 20,
    textAlign: 'left',
    padding: 10,
    boxShadow: 'none',
    border: `1px solid ${colorText}`,
    backgroundColor: backgroundColor ? backgroundColor?.hex : 'transparent'
  },
  txtButton: {
    backgroundColor: '#ff4940',
    border: '1px solid #ff4940',
    color: '#ffeef5',
    fontSize: 14,
    padding: '3px 16px',
    width: 'max-content',
    borderRadius: 8
  },
  txtTestimonial: {
    margin: '35px 0px 35px 0px',
    fontSize: 18,
    color: colorText,
    fontWeight: 400
  },
  txtBy: {
    fontSize: 14,
    color: colorText,
    fontWeight: 700,
    margin: '35px 0px 35px 0px'
  },
  cardImg: {
    width: 'auto',
    height: 'auto',
    maxWidth: 90,
    maxHeight: 90,
    boxShadow: '0px 3px 6px #00000012',
    marginRight: 15
  },
  contentImg: {
    padding: 10,
    paddingBottom: 'unset !important'
  },
  txtName: {
    fontSize: 14,
    fontWeight: 400,
    color: colorText
  }
}))
