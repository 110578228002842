import { makeStyles } from 'tss-react/mui'

export default makeStyles()((theme) => ({
  createAccountTitle: {
    fontSize: 24,
    fontWeight: 600,
    color: '#000',
    textAlign: 'center',
    lineHeight: '48px',
    [theme.breakpoints.up('md')]: {
      fontSize: 32
    }
  },
  createAccountSubtitle: {
    fontSize: 16,
    fontWeight: 400,
    textAlign: 'center'
  },
  createAccountLink: {
    textAlign: 'center',
    fontSize: 16,
    fontWeight: 700,
    color: '#4589FF',
    textDecoration: 'underline'
  },
  inputIcon: {
    marginRight: '0.5rem',
    verticalAlign: 'bottom',
    cursor: 'pointer',
    [theme.breakpoints.down('xs')]: {
      maxWidth: '1rem',
      verticalAlign: 'middle'
    }
  },
  formInputFocused: {
    border: '1px solid #007AFF'
  },
  formInputError: {
    border: '1px solid #ff441f'
  },
  containerNames: {
    display: 'grid',
    gridTemplateColumns: '1fr',
    alignItems: 'center',
    marginTop: -16,
    [theme.breakpoints.up('sm')]: {
      gridTemplateColumns: '1fr 1fr',
      gap: 16
    }
  },
  containerPhone: {
    display: 'grid',
    gridTemplateColumns: 'max-content 1fr',
    gap: 16,
    alignItems: 'center'
  },
  passwordTooltip: {
    background: '#FFF',
    padding: 24,
    boxShadow: '0 10px 24px 0 rgba(0, 0, 0, 0.3)'
  },
  passwordTooltipArrow: {
    color: '#FFF'
  },
  phoneTooltipContainer: {
    marginRight: 10
  },
  phoneTooltip: {
    background: 'rgba(32, 32, 32, 0.92)',
    fontSize: 16,
    fontWeight: 600,
    lineHeight: '20px',
    borderRadius: 10,
    padding: '16px 8px',
    transform: 'translateX(20px) !important'
  },
  phoneTooltipArrow: {
    color: 'rgba(32, 32, 32, 0.92)',
    transform: 'translateX(-20px) !important'
  },
  checkbox: {
    marginTop: 10
  },
  submitButton: {
    height: 40,
    marginTop: 24,
    fontSize: '14px !important',
    fontWeight: '600 !important',
    '&:disabled': {
      opacity: 1,
      color: '#9E9E9F',
      background: '#EDEDED',
      border: 'none'
    },
    [theme.breakpoints.up('sm')]: {
      height: 56,
      fontSize: '16px !important'
    }
  },
  linkLogin: {
    display: 'block',
    width: 'fit-content',
    margin: '16px auto 0',
    color: '#007AFF',
    fontSize: 14,
    fontWeight: 600,
    textAlign: 'center',
    textDecoration: 'none',
    [theme.breakpoints.up('sm')]: {
      fontSize: 16,
      marginTop: 24
    },
    '& strong': {
      fontSize: 16,
      fontWeight: 700,
      color: '#4589FF',
      textDecoration: 'underline'
    }
  },
  requiredText: {
    color: '#868D97',
    fontSize: 12
  },
  gridRequired: { paddingTop: '0px !important' }
}))
