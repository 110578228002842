// @TODO: Mover a configuracion
const phoneLengths = {
  AR: { min: 11, max: 11 },
  BR: { min: 10, max: 14 },
  CL: { min: 9, max: 9 },
  CO: { min: 10, max: 10 },
  CR: { min: 8, max: 8 },
  EC: { min: 10, max: 10 },
  MX: { min: 10, max: 10 },
  PE: { min: 9, max: 9 },
  UY: { min: 9, max: 9 }
}

export const emailValidation = /^[a-zA-Z0-9.!#$%&'*+/=?^_`{|}~-]+@[a-zA-Z0-9-]+(?:\.[a-zA-Z0-9-]+)*$/
export const minCharacters = /(?:.{8,})/
export const minLetters = /(?:.*[a-zA-Z]){1}/
export const minSpecialCharacters = /[\!\@\#\$\%\^\&\*\-\_]/
export const minNumbers = /(?:.*[0-9]){1}/
export const nameValidation = /[a-zA-Z]+/
export const onlyNumbers = /^[0-9]+$/

export const validatePhoneNumber = (number, country) => {
  const { length } = number
  const { min, max } = phoneLengths[country] || { min: 8, max: 11 }
  return onlyNumbers.test(number) && min <= length && max >= length
}
