import { backgroundColorField, ctaField } from '@/defaultSchema/fields'
import { defineType } from 'sanity'

export default defineType({
  type: 'object',
  title: 'FAQ',
  name: 'faqs',
  fields: [
    {
      type: 'blockContent',
      name: 'text',
      title: 'Text',
      description: 'The text h1, h2, h3, h4, small, p, etc',
      validation: (Rule) => Rule.required()
    },
    backgroundColorField,
    {
      type: 'array',
      name: 'faqs',
      title: 'FAQs',
      validation: (Rule) => Rule.required(),
      of: [
        {
          type: 'object',
          name: 'faq',
          fields: [
            {
              type: 'string',
              name: 'question',
              title: 'Question',
              validation: (Rule) => Rule.required()
            },
            {
              type: 'text',
              name: 'answer',
              title: 'Answer',
              validation: (Rule) => Rule.required()
            },
            ctaField()
          ]
        }
      ]
    }
  ],
  preview: {
    select: {
      title: 'title',
      question: 'faqs.question',
      answer: 'faqs.answer'
    },
    prepare({ title }) {
      return {
        title: 'FAQ',
        subtitle: title
      }
    }
  }
})
