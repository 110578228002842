import { defineType } from 'sanity'
import { IoVideocam } from 'react-icons/io5'
import { backgroundColorField } from '@/defaultSchema/fields'

export default defineType({
  type: 'object',
  name: 'sectionVideo',
  title: 'Video',
  initialValue: {
    fullHeight: false,
    loop: false
  },
  fields: [
    {
      type: 'file',
      title: 'Video',
      name: 'videoFile',
      options: {
        accept: 'video/mp4, video/webm'
      },
      fields: [
        {
          type: 'string',
          name: 'alt',
          title: 'Alt',
          validation: (Rule) => Rule.required()
        },
        {
          type: 'boolean',
          name: 'loop',
          title: 'Lopp',
          description: 'Select true if you want the video to loop'
        }
      ],
      validation: (Rule) => Rule.required()
    },
    {
      type: 'boolean',
      name: 'fullHeight',
      title: 'full height',
      description: 'Select true if you want the banner to be 100% of the screen height'
    },
    backgroundColorField
  ],
  preview: {
    select: {
      alt: 'file.alt'
    },
    prepare({ alt }) {
      return {
        title: 'Video',
        alt,
        icon: IoVideocam
      }
    }
  }
})
