/* eslint-disable react/no-children-prop */
/* eslint-disable react/display-name */
import { Typography, ListItem } from '@mui/material'
import { PortableText } from '@portabletext/react'
import Link from 'next/link'
import Span from '../html/Span'
import Strike from '../html/Strike'
import TextAlign from '../html/TextAling'
import urlFor from '@/lib/urlFor'
import useStyles from './Text.styles'

export default function Text({ blockContent }) {
  const { classes } = useStyles()

  const renderTypography =
    (component) =>
    ({ children }) => {
      const variants = {
        h1: 'h1',
        h2: 'h2',
        h3: 'h3',
        h4: 'h4',
        h5: 'h5',
        h6: 'h6',
        body1: 'p',
        body2: 'p',
        inherit: 'p'
      }
      return (
        <Typography component={variants[component]} variant={component}>
          {children}
        </Typography>
      )
    }

  const components = {
    block: {
      h1: renderTypography('h1'),
      h2: renderTypography('h2'),
      h3: renderTypography('h3'),
      h4: renderTypography('h4'),
      h5: renderTypography('h5'),
      h6: renderTypography('h6'),
      normal: renderTypography('body1'),
      small: renderTypography('body2')
    },
    listItem: ({ children, value }) => {
      return (
        <ListItem component='li' dense sx={{ display: 'list-item', color: value.markDefs[0]?.hex }}>
          {children}
        </ListItem>
      )
    },
    marks: {
      strikeThrough: Strike,
      left: ({ children }) => <TextAlign children={children} align='left' />,
      center: ({ children }) => <TextAlign children={children} align='center' />,
      right: ({ children }) => <TextAlign children={children} align='right' />,
      color: Span,
      link: ({ children, value }) => {
        const rel = !value?.href?.startsWith('/') ? 'noreferrer noopener' : ''
        return (
          <Link href={`${value?.href}`} rel={rel} className={classes.link}>
            {children}
          </Link>
        )
      }
    },
    types: {
      image: ({ value }) => {
        return (
          // eslint-disable-next-line @next/next/no-img-element
          <img
            src={urlFor(value)}
            alt={value.alt || ''}
            loading='lazy'
            style={{
              width: '100%'
            }}
          />
        )
      }
    }
  }

  return <PortableText value={blockContent} components={components} />
}
