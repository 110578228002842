import { makeStyles } from 'tss-react/mui'

export default makeStyles()((theme, { backgroundColor }) => ({
  container: {
    padding: '40px 24px',
    background: backgroundColor ? backgroundColor?.hex : 'transparent',
    textAlign: 'center',
    [theme.breakpoints.up('md')]: {
      padding: '60px 50px'
    },
    width: '100%'
  },
  containerTitles: {
    textAlign: 'left',
    width: '100%'
  },
  title: {
    marginBottom: 10,
    fontSize: 32,
    fontWeight: 600
  }
}))
