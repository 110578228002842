import { useCallback, useEffect, useRef, useState } from 'react'
import { DEFAULT_COUNTRY } from '@/constants/Config'
import { countryCode } from '@/constants/CountryCode'
import { CountryCodeFlag } from '@/constants/CountryCodeFlag'
import { SIGNUP_NAME } from '@/constants/FieldNames'
import { GTM_EVENTS } from '@/constants/HotjarGTMConstants'
import { EYE_CLOSED_ICON, EYE_OPEN_ICON } from '@/constants/Icons'
import { MASK_PHONE_BY_COUNTRY, phonePrefixes } from '@/constants/PhoneLenghts'
import { LINK_TO_MI_TIENDA } from '@/constants/SignupConstants'
import { phoneRemoveCharacter } from '@/utils/phoneUtilities'
import { sendTagManager } from '@/utils/tagManager'
import {
  Button,
  Checkbox,
  FormControlLabel,
  Grid,
  InputAdornment,
  Tooltip,
  Typography,
  useMediaQuery
} from '@mui/material'
import { Field, Formik } from 'formik'
import { capitalize as lodashCapitalize } from 'lodash'
import Image from 'next/image'
import Link from 'next/link'
import { useRouter } from 'next/router'
import { useGoogleReCaptcha } from 'react-google-recaptcha-v3'
import { FormattedMessage } from 'react-intl'
import InputPhone from '../../../input-phone/InputPhone'
import Input from '../../../input/Input'
import ReferralCodeInput from '../../../referral-code-input/ReferralCodeInput'
import Select from '../../../select/Select'
import SignupPasswordRules from '../../../signup-password-rules/SignupPasswordRules'
import CreateAccountFormSchema from './CreateAccount.schema'
import useStyles from './CreateAccount.styled'

const CreateAccountForm = ({ onSubmitSuccess }) => {
  const { classes } = useStyles()
  const router = useRouter()
  const country = router.locale.split('-')[1]?.toUpperCase() || DEFAULT_COUNTRY
  const formikRef = useRef()
  const passwordRef = useRef(null)
  const isMobileShort = useMediaQuery((theme) => theme.breakpoints.down('xs'))
  const [loading, setLoading] = useState(false)
  const [showPasswordTooltip, setShowPasswordTooltip] = useState(false)
  const [isPasswordShown, setShowPassword] = useState(false)
  const [isReferralCodeValid, setIsReferralCodeValid] = useState(false)
  const [hasReferralCode, setHasReferralCode] = useState(router.query.utm_referral_code ?? '')
  const [phoneMask, setPhoneMask] = useState(null)
  const [phoneValue, setPhoneValue] = useState(null)
  const [prefix, setPrefix] = useState(`${CountryCodeFlag.CO.code}|CO`)
  const [referralCode, setReferralCode] = useState(null)
  const { executeRecaptcha } = useGoogleReCaptcha()

  useEffect(() => {
    if (!executeRecaptcha) return
    const handleReCaptchaVerify = async () => await executeRecaptcha('register')
    handleReCaptchaVerify()
  }, [executeRecaptcha])

  useEffect(() => {
    setPrefix(`${CountryCodeFlag[country]?.code}|${country}`)
  }, [country])

  useEffect(() => {
    setHasReferralCode(router.query.utm_referral_code ?? '')
  }, [router.query])

  const registerUser = useCallback(async () => {
    if (!executeRecaptcha) return
    await executeRecaptcha('register')
  }, [executeRecaptcha])

  const mapPrefixes = phonePrefixes.map((prefix) => ({
    ...prefix,
    value: `${prefix.value}|${prefix.country}`,
    prefix: prefix.value
  }))

  const selectPrefix = (event, setFieldValue) => {
    const country = event.target?.dataset?.value?.split('|')
    if (country) {
      setPhoneMask(MASK_PHONE_BY_COUNTRY[country[1]])
      setFieldValue('phone', '')
      setPhoneValue('')
    }
  }

  const phoneByCountry = (phone, prefix) => {
    const [country] = prefix
    return country === countryCode.ECUADOR ? phoneRemoveCharacter(phone, 1) : phone
  }

  return (
    <Formik
      enableReinitialize
      initialValues={{
        name: '',
        lastname: '',
        phonePrefix: prefix,
        phone: '',
        email: '',
        password: '',
        referralCode: router.query.utm_referral_code ?? ''
      }}
      
      validationSchema={() => CreateAccountFormSchema(country)}
      
      onSubmit={(values, formikHelper) => {
        setLoading(true)
        const prefix = values.phonePrefix.split('|')
        
        const payload = {
          ...values,
          country: country,
          name: values.name.split(' ').map(lodashCapitalize).join(' ').trim(),
          lastName: values.lastname.split(' ').map(lodashCapitalize).join(' ').trim(),
          phonePrefix: prefix[0],
          phone: phoneByCountry(values.phone, prefix),
          referralCode: referralCode,
          source: router.query.source,
          isCcp: false,
          campaign: router.query.medium,
          medium: router.query.medium,
        }

        sendTagManager(GTM_EVENTS.singUp, payload)
        registerUser()

        onSubmitSuccess(payload)
      }}
    >
      {({ handleSubmit, isValid, dirty, values, errors, setFieldValue }) => (
        <form onSubmit={handleSubmit}>
          <Grid container>
            <Grid container gap='16px' marginTop='16px'>
              <Grid container spacing='16px'>
                <Grid item xs={12} sm={6}>
                  <Field
                    id={SIGNUP_NAME}
                    name='name'
                    component={Input}
                    label={<FormattedMessage id='selfOnboardingPage.landingPage.hero.createAccountForm.field.name' />}
                    InputProps={{
                      classes: { error: classes.formInputError, focused: classes.formInputFocused }
                    }}
                  />
                </Grid>
                <Grid item xs={12} sm={6}>
                  <Field
                    name='lastname'
                    component={Input}
                    label={
                      <FormattedMessage id='selfOnboardingPage.landingPage.hero.createAccountForm.field.lastname' />
                    }
                    InputProps={{
                      classes: { error: classes.formInputError, focused: classes.formInputFocused }
                    }}
                    id='signupLastname'
                  />
                </Grid>
              </Grid>
              <Grid container spacing='16px'>
                <Grid item xs={6}>
                  <Field
                    name='phonePrefix'
                    component={Select}
                    options={mapPrefixes}
                    onClick={(event) => selectPrefix(event, setFieldValue)}
                    id='signupPhonePrefix'
                  />
                </Grid>
                <Grid item xs={6}>
                  <InputPhone
                    name='phone'
                    value={phoneValue}
                    mask={phoneMask}
                    formikRef={formikRef}
                    country={country}
                    setPhoneMask={setPhoneMask}
                    setFieldValue={setFieldValue}
                    setPhoneValue={setPhoneValue}
                    label={
                      isMobileShort ? (
                        <FormattedMessage id='selfOnboardingPage.landingPage.hero.createAccountForm.field.phone.mobile' />
                      ) : (
                        <FormattedMessage id='selfOnboardingPage.landingPage.hero.createAccountForm.field.phone' />
                      )
                    }
                    inputProps={{
                      classes: { error: classes.formInputError, focused: classes.formInputFocused }
                    }}
                    formHelperTextProps={{ hidden: errors['phone'] }}
                    id='signupPhone'
                  />
                </Grid>

                <Grid item xs={12} className={classes.gridRequired}>
                  <Typography className={classes.requiredText}>
                    <FormattedMessage id='selfOnboardingPage.landingPage.hero.createAccountForm.field.phone.helperText' />
                  </Typography>
                </Grid>
              </Grid>
              <Grid container>
                <Field
                  name='email'
                  component={Input}
                  label={<FormattedMessage id='selfOnboardingPage.landingPage.hero.createAccountForm.field.mail' />}
                  InputProps={{
                    classes: { error: classes.formInputError, focused: classes.formInputFocused }
                  }}
                  id='signupEmail'
                />
              </Grid>
              <Grid container>
                <Field
                  innerref={passwordRef}
                  name='password'
                  type={isPasswordShown ? 'text' : 'password'}
                  component={Input}
                  label={<FormattedMessage id='selfOnboardingPage.landingPage.hero.createAccountForm.field.password' />}
                  InputProps={{
                    endAdornment: (
                      <InputAdornment
                        onClick={(e) => {
                          e.preventDefault()
                          setShowPassword((prevState) => !prevState)
                        }}
                        position='end'
                        className={classes.inputIcon}
                      >
                        <Image
                          width={24}
                          height={24}
                          alt={isPasswordShown ? '' : ''}
                          src={isPasswordShown ? EYE_OPEN_ICON : EYE_CLOSED_ICON}
                        />
                      </InputAdornment>
                    ),
                    classes: { error: classes.formInputError, focused: classes.formInputFocused }
                  }}
                  onFocus={() => setShowPasswordTooltip(true)}
                  onBlur={() => setShowPasswordTooltip(false)}
                  id='signupPassword'
                />
                <Tooltip
                  arrow
                  classes={{
                    tooltip: classes.passwordTooltip,
                    arrow: classes.passwordTooltipArrow,
                    popper: classes.passwordTooltipPopper
                  }}
                  open={showPasswordTooltip}
                  disableHoverListener
                  disableTouchListener
                  placement='bottom-start'
                  title={<SignupPasswordRules password={values.password} />}
                >
                  <div ref={passwordRef}></div>
                </Tooltip>
              </Grid>
            </Grid>

            <FormControlLabel
              className={classes.checkbox}
              control={
                <Checkbox
                  checked={!!hasReferralCode}
                  onChange={() => {
                    setHasReferralCode((prev) => !prev)
                  }}
                  color='primary'
                />
              }
              label={<FormattedMessage id='selfOnboardingPage.landingPage.hero.createAccountForm.checkbox.label' />}
            />
            {hasReferralCode && (
              <Field
                name='referralCode'
                component={ReferralCodeInput}
                country={country}
                referralCodeQuery={router.query.utm_referral_code}
                setReferralCode={setReferralCode}
                setIsReferralCodeValid={setIsReferralCodeValid}
                label={
                  <FormattedMessage id='selfOnboardingPage.landingPage.hero.createAccountForm.field.referralCode' />
                }
                InputProps={{
                  classes: { error: classes.formInputError, focused: classes.formInputFocused }
                }}
                id='signupReferralCode'
              />
            )}
            <Button
              fullWidth
              type='submit'
              variant='contained'
              disabled={!isValid || !dirty || loading || (hasReferralCode && !isReferralCodeValid)}
              className={classes.submitButton}
            >
              <FormattedMessage id='selfOnboardingPage.landingPage.hero.createAccountForm.button.submit' />
            </Button>
            <Link href={`${LINK_TO_MI_TIENDA[country]}`} target='_blank' className={classes.linkLogin} rel='noreferrer'>
              <Typography fontWeight='600'>
                <FormattedMessage id='selfOnboardingPage.landingPage.hero.createAccountForm.link' />
              </Typography>
            </Link>
          </Grid>
        </form>
      )}
    </Formik>
  )
}

export default CreateAccountForm
