import { Grid, Typography, Box } from '@mui/material'

import useStyles from './SectionCardsTestimonial.styles'
import CardTestimonial from '../card-testimonial/CardTestimonial'
import { getTextColor } from '@/utils/getTextColor'
import Text from '../text/Text'

const SectionCardsTestimonial = ({ text, backgroundColor, cards }) => {
  const textColor = getTextColor(backgroundColor ? backgroundColor.hex : '#FAFAFA')

  const { classes } = useStyles({ backgroundColor: backgroundColor, textColor: textColor })
  return (
    <Grid component='section' className={classes.container}>
      {text && (
        <Grid className={classes.containerTitles}>
          <Text blockContent={text} textColor={textColor} />
        </Grid>
      )}

      <Box sx={{ flexGrow: 1 }}>
        <Grid container justifyContent='center' spacing={3}>
          {cards?.map((card, i) => (
            <CardTestimonial
              key={i}
              txtButton={card.txtButton}
              testimonial={card.testimonial}
              txtBy={card.txtBy}
              logoRestaurant={card.logoRestaurant}
              nameRestaurant={card.nameRestaurant}
              backgroundColor={card.backgroundColor}
            />
          ))}
        </Grid>
      </Box>
    </Grid>
  )
}

export default SectionCardsTestimonial
