import { makeStyles } from 'tss-react/mui'

export default makeStyles()((theme, { backgroundColor, textColor }) => ({
  container: {
    padding: '40px 24px',
    background: backgroundColor ? backgroundColor.hex : '#FAFAFA',
    textAlign: 'center',
    [theme.breakpoints.up('md')]: {
      padding: '40px 50px'
    },
    display: 'grid'
  },
  gridImg: {
    display: 'flex'
  },
  image: {
    margin: 8,
    maxWidth: 150
  },
  btnCTA: {
    padding: 15,
    backgroundColor: '#FFF',
    borderColor: 'FFF',
    color: '#0ec665',
    '&:hover': {
      backgroundColor: '#0ec665',
      borderColor: '#FFF',
      color: '#FFF'
    }
  }
}))
