import * as React from 'react'
import { Field } from 'formik'
import InputMask from 'react-input-mask'

import Input from '../input/Input'
import useStyles from './InputPhone.styles'
import { MASK_PHONE_BY_COUNTRY } from '@/constants/PhoneLenghts'

export default function InputPhone({
  name,
  value,
  mask,
  formikRef,
  disabled,
  country,
  setPhoneMask,
  setPhoneValue,
  setFieldValue,
  label,
  formHelperTextProps,
  helperText,
  id
}) {
  const { classes } = useStyles()

  React.useEffect(() => {
    setPhoneMask(MASK_PHONE_BY_COUNTRY[country])
    setPhoneValue('')
  }, [country])

  const setValuePhone = (event) => {
    const phone = event?.target.value.replace(/[^\d]/g, '')
    setFieldValue(name, phone)
    setPhoneValue(phone)
  }

  return (
    <InputMask
      className={value == 0 || value == 9 ? classes.inputTextOnFocus : classes.inputTextFocus}
      mask={mask}
      maskChar={'X'}
      // maskPlaceholder={value === 0 || value === 9 ? 'X' : null}
      disabled={disabled}
      value={value}
      onChange={(event) => setValuePhone(event)}
    >
      {(inputProps) => (
        <Field
          fullWidth
          id={id}
          name={name}
          component={Input}
          label={label}
          InputProps={inputProps}
          FormHelperTextProps={formHelperTextProps}
          helperText={helperText}
        />
      )}
    </InputMask>
  )
}
