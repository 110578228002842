import { makeStyles } from 'tss-react/mui'

export default makeStyles()((theme, { backgroundColor, textColor }) => ({
  container: {
    padding: '40px 24px',
    background: backgroundColor ? backgroundColor?.hex : '#FAFAFA',
    textAlign: 'center',
    [theme.breakpoints.up('md')]: {
      padding: '40px 50px'
    }
  },
  gridContainer: {
    padding: 35,
    margin: '0 auto',
    maxWidth: 1280,
    backgroundColor: backgroundColor ? backgroundColor.hex : '#FAFAFA'
  },
  containerTitles: {
    display: 'grid',
    paddingBottom: 40,
    width: '100%'
  }
}))
