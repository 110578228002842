import { defineField } from 'sanity'

export const ctaField = ({ name = 'cta', ...rest } = {}) =>
  defineField({
    type: 'object',
    name: name,
    title: 'CTA',
    description: 'The Call to action <a>{cta}</a>',
    validation: (Rule) => {
      return Rule.custom((fields) => {
        if (fields?.label && !fields?.link) return 'Link required'
        return true
      })
    },
    fields: [
      {
        type: 'string',
        name: 'label',
        title: 'label',
        description: 'The Call to action <a>{label}</a>'
      },
      {
        type: 'string',
        name: 'link',
        title: 'Link',
        description: 'The Call to action <a href={linkCTA}>',
        hidden: ({ parent }) => !parent?.label
      },
      {
        type: 'string',
        name: 'target',
        title: 'Target',
        description: () => {
          return (
            <>
              <p>The target of the call to action &lt;a target={'_blank' | '_self' | '_parent' | '_top'}&gt;</p>
              <a href='https://developer.mozilla.org/en-US/docs/Web/HTML/Element/a#attributes' target='_blank'>
                Docs
              </a>
            </>
          )
        },
        options: {
          list: [
            { title: 'Text', value: 'text' },
            { title: 'Outlined', value: 'outlined' },
            { title: 'Contained', value: 'contained' }
          ]
        },
        hidden: ({ parent }) => !parent?.label
      },
      {
        type: 'string',
        name: 'variant',
        title: 'Variant',
        description: 'The variant of the button',
        options: {
          list: [
            { title: 'Text', value: 'text' },
            { title: 'Outlined', value: 'outlined' },
            { title: 'Contained', value: 'contained' }
          ]
        },
        hidden: ({ parent }) => !parent?.label
      }
    ],
    ...rest
  })

export const backgroundColorField = {
  type: 'color',
  name: 'backgroundColor',
  title: 'Background Color'
}

export const textField = ({ name = 'text', ...rest } = {}) =>
  defineField({
    type: 'blockContent',
    name: name,
    title: 'Text',
    description: 'The text h1, h2, h3, h4, small, p, etc',
    ...rest
  })

export const imageField = ({ name = 'image', ...rest } = {}) =>
  defineField({
    type: 'image',
    name: name,
    title: 'image <img>',
    options: {
      hotspot: true
    },
    validation: (Rule) => {
      return Rule.custom((fields) => {
        if (fields?.asset && !fields?.alt) return 'Alt required'
        return true
      })
    },
    fields: [
      {
        name: 'alt',
        type: 'string',
        title: 'Alt'
      },
      {
        type: 'boolean',
        name: 'containSize',
        title: 'Contain size',
        description: 'Adjust the size of the image',
        initialValue: false
      }
    ],
    hidden: ({ parent }) => parent?.withSignupForm,
    ...rest
  })

export const borderColorField = {
  type: 'color',
  name: 'borderColor',
  title: 'Border color',
  description: 'Select border color element'
}

export const seoTitleField = ({ name = 'seoTitle', ...rest } = {}) =>
  defineField({
    type: 'string',
    name: name,
    title: 'SEO Title',
    description: '<title>...<title/>',
    group: 'seo',
    validation: (Rule) => Rule.required(),
    ...rest
  })

export const seoDescriptionField = ({ name = 'seoDescription', ...rest } = {}) =>
  defineField({
    type: 'string',
    name: name,
    title: 'SEO Description',
    description: '<meta name="description" content="..." />',
    group: 'seo',
    validation: (Rule) => Rule.required(),
    ...rest
  })

export const seoRobotsField = ({ name = 'seoRobots', ...rest } = {}) =>
  defineField({
    type: 'string',
    name: name,
    title: 'SEO Robots',
    description: '<meta name="robots" content="..." />',
    group: 'seo',
    ...rest
  })

export const seoHrefLangField = ({ name = 'hreflang', slugFieldName, ...rest } = {}) =>
  defineField({
    type: 'slug',
    name: name,
    title: 'hreflang',
    group: 'seo',
    options: {
      source: slugFieldName,
      slugify: (slug) => `${process.env.NEXT_PUBLIC_URL}/${slug?.current}`
    },
    validation: (Rule) => Rule.required(),
    ...rest
  })

export const seoCanonicalField = ({ name = 'seoCanonical', slugFieldName, ...rest } = {}) =>
  defineField({
    type: 'slug',
    name: name,
    title: 'SEO Canonical url',
    description: '<link rel="canonical" href="..." />',
    group: 'seo',
    options: {
      source: slugFieldName,
      slugify: (slug) => `${process.env.NEXT_PUBLIC_URL}/${slug?.current}`
    },
    validation: (Rule) => Rule.required(),
    ...rest
  })

export const ogTitleField = ({ name = 'ogTitle', ...rest } = {}) =>
  defineField({
    type: 'string',
    name: name,
    title: 'OG Title',
    description: '<meta name="og:title" content="..." />',
    group: 'ogBasics',
    ...rest
  })

export const ogDescriptionField = ({ name = 'ogDescription', ...rest } = {}) =>
  defineField({
    type: 'string',
    name: name,
    title: 'OG Description',
    description: '<meta name="og:description" content="..." />',
    group: 'ogBasics',
    ...rest
  })

export const ogTypeField = ({ name = 'ogType', ...rest } = {}) =>
  defineField({
    type: 'string',
    name: name,
    title: 'OG Type',
    description: '<meta name="og:type" content="..." />',
    group: 'ogBasics',
    ...rest
  })

export const ogUrlField = ({ name = 'ogUrl', ...rest } = {}) =>
  defineField({
    type: 'string',
    name: name,
    title: 'OG Url',
    description: '<meta name="og:url" content="..." />',
    group: 'ogBasics',
    ...rest
  })

export const ogImageField = ({ name = 'ogImage', ...rest } = {}) =>
  defineField({
    type: 'string',
    name: name,
    title: 'OG Image',
    description: '<meta name="og:image" content="..." />',
    group: 'ogBasics',
    ...rest
  })

export const ogSiteNameField = ({ name = 'ogSiteName', ...rest } = {}) =>
  defineField({
    type: 'string',
    name: name,
    title: 'OG Site Name',
    description: '<meta name="og:site_name" content="..." />',
    group: 'ogBasics',
    ...rest
  })

export const ogLocaleField = ({ name = 'ogLocale', ...rest } = {}) =>
  defineField({
    type: 'string',
    name: name,
    title: 'OG Locale',
    description: '<meta name="og:locale" content="..." />',
    group: 'ogBasics',
    ...rest
  })

export const ogAudioField = ({ name = 'ogAudio', ...rest } = {}) =>
  defineField({
    type: 'string',
    name: name,
    title: 'OG Audio',
    description: '<meta name="og:audio" content="..." />',
    group: 'ogBasics',
    ...rest
  })

export const ogVideoField = ({ name = 'ogVideo', ...rest } = {}) =>
  defineField({
    type: 'string',
    name: name,
    title: 'OG Video',
    description: '<meta name="og:video" content="..." />',
    group: 'ogBasics',
    ...rest
  })

export const ogFields = [
  ogTitleField(),
  ogDescriptionField(),
  ogTypeField(),
  ogUrlField(),
  ogImageField(),
  ogSiteNameField(),
  ogLocaleField(),
  ogAudioField(),
  ogVideoField()
]
