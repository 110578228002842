import { Button, Card, CardActions, CardContent, CardMedia, Grid, Typography } from '@mui/material'
import Image from 'next/image'
import urlFor from '@/lib/urlFor'

import useStyles from './CardTestimonial.styles'
import { getTextColor } from '@/utils/getTextColor'

const CardTestimonial = ({ txtButton, testimonial, txtBy, logoRestaurant, nameRestaurant, backgroundColor }) => {
  const imageUrl = urlFor(logoRestaurant)
  const colorText = getTextColor(backgroundColor?.hex)

  const { classes } = useStyles({ colorText: colorText, backgroundColor: backgroundColor })

  return (
    <Grid item md={4} sm={12} sx={{ marginBottom: 10 }}>
      <Card className={classes.card} sx={{ height: '100%' }}>
        <CardContent>
          <Typography className={classes.txtButton}>{txtButton}</Typography>

          <Typography className={classes.txtTestimonial}> {testimonial}</Typography>

          {txtBy && <Typography className={classes.txtBy}> {txtBy}</Typography>}

          <Grid container direction='row' justifyContent='left' alignItems='center'>
            {imageUrl && (
              <Card className={classes.cardImg}>
                <CardContent className={classes.contentImg}>
                  <Image
                    src={imageUrl}
                    alt={logoRestaurant?.alt}
                    width={70}
                    height={50}
                    className={classes.iconImage}
                  />
                </CardContent>
              </Card>
            )}

            <Typography className={classes.txtName}> {nameRestaurant}</Typography>
          </Grid>
        </CardContent>
      </Card>
    </Grid>
  )
}

export default CardTestimonial
