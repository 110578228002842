import { OTP_CODE_LENGTH } from '@/constants/SignupConstants'
import useStyles from './OtpInput.styles'


const OtpInput = ({name, placeholder, setFieldValue, errors, valuesCodes }) => {

  const { classes, cx } = useStyles()

  const onChange = (e, index) => {
    const value = e.target.value.toUpperCase()
    const nextInput = e.target.nextSibling

    if (e.target.value === ' ') return

    if (e.target.value.length === 1) {
      const newValues = [...valuesCodes]
      newValues[index] = value
      setFieldValue(name, newValues)
      if (nextInput) nextInput.focus()
      e.target.setSelectionRange(0, e.target.value.length)
    }
  }

  const onKeyDown = (e, index) => {
    const nextInput = e.target.nextSibling
    const prevInput = e.target.previousSibling

    e.target.setSelectionRange(0, e.target.value.length)
    if (e.key === 'Backspace') {
      const newValues = [...valuesCodes]
      newValues[index] = ''
      setFieldValue(name, newValues)
      if (prevInput && (nextInput?.value === '' || !nextInput)) prevInput.focus()
    }

    if (e.key === 'ArrowRight' || e.key === 'ArrowUp') {
      e.preventDefault()
      if (nextInput) nextInput.focus()
    }

    if (e.key === 'ArrowLeft' || e.key === 'ArrowDown') {
      e.preventDefault()
      if (prevInput) prevInput.focus()
    }
  }

  const onPaste = (e) => {
    e.preventDefault()
    const textToPaste = e.clipboardData.getData('text').toUpperCase()
    if (textToPaste.length >= OTP_CODE_LENGTH)
      setFieldValue(name, textToPaste.split('').slice(0, OTP_CODE_LENGTH))
    else
      setFieldValue(
        name,
        valuesCodes.map((_, i) => textToPaste[i] || '')
      )
    e.target.blur()
  }

  return (
    valuesCodes.map((value, index) => {
      return (
        <input
          key={index}
          autoComplete='off'
          name={name}
          type='text'
          placeholder={placeholder}
          className={cx(classes.otpInput, errors[name] ? classes.otpInputError : '')}
          value={value}
          onPaste={onPaste}
          onChange={(e) => onChange(e, index)}
          onKeyDown={(e) => onKeyDown(e, index)}
        />
      )
    })
  )
}

export default OtpInput

