import React from 'react'
import { FormattedMessage } from 'react-intl'

import useStyles from './SignupPasswordRules.styles'
import CheckIcon from '@/icons/CheckIcon'
import CheckDisabledIcon from '@/icons/CheckDisabledIcon'
import { minCharacters, minLetters, minNumbers, minSpecialCharacters } from '@/utils/inputsValidation'

export default function SignupPasswordRules({ password }) {
  const { classes } = useStyles()

  const iconCheck = (chekedValue) => {
    if (chekedValue) {
      return <CheckIcon />
    }
    return <CheckDisabledIcon />
  }

  return (
    <>
      <p className={classes.description}>
        <FormattedMessage id='selfOnboardingPage.landingPage.hero.createAccountForm.field.password.error.restrictions' />
      </p>
      <div className={classes.rules}>
        <div className={`${classes.rule} ${minCharacters.test(password) && classes.activated}`}>
          {iconCheck(minCharacters.test(password))}
          <span>
            <FormattedMessage id='selfOnboardingPage.landingPage.hero.createAccountForm.field.password.error.minCharacters' />
          </span>
        </div>

        <div className={`${classes.rule} ${minLetters.test(password) && classes.activated}`}>
          {iconCheck(minLetters.test(password))}
          <span>
            <FormattedMessage id='selfOnboardingPage.landingPage.hero.createAccountForm.field.password.error.minLetters' />
          </span>
        </div>

        <div className={`${classes.rule} ${minNumbers.test(password) && classes.activated}`}>
          {iconCheck(minNumbers.test(password))}
          <span>
            <FormattedMessage id='selfOnboardingPage.landingPage.hero.createAccountForm.field.password.error.minNumbers' />
          </span>
        </div>

        <div className={`${classes.rule} ${minSpecialCharacters.test(password) && classes.activated}`}>
          {iconCheck(minSpecialCharacters.test(password))}
          <span>
            <FormattedMessage id='selfOnboardingPage.landingPage.hero.createAccountForm.field.password.error.minCharacterSpecial' />
          </span>
        </div>
      </div>
    </>
  )
}
