import * as React from 'react'
import { Grid } from '@mui/material'
import getUrlFile from '@/lib/getUrlFile'

export default function SectionVideo({ videoFile, fullHeight, backgroundColor }) {
  const video = getUrlFile(videoFile)

  return (
    <Grid
      container
      component='section'
      sx={(theme) => ({
        width: '100%',
        minHeight: fullHeight ? 'calc(100vh - 64px)' : 'auto',
        padding: '40px 24px',
        backgroundColor: backgroundColor ? backgroundColor?.hex : 'transparent',
        [theme.breakpoints.up('md')]: {
          padding: '60px 50px'
        }
      })}
    >
      <Grid
        sx={{
          width: '100%',
          maxWidth: 1280,
          margin: '0 auto',
          display: 'grid',
          gridTemplateColumns: '1fr',
          gridAutoRows: 'max-content',
          justifyContent: 'center',
          alignContent: 'center',
          gap: '20px'
        }}
      >
        <Grid display='grid' gap='20px' sx={{ borderRadius: '10px', overflow: 'hidden' }}>
          {video && (
            <video
              title={videoFile.alt}
              width='100%'
              height='auto'
              style={{ maxHeight: fullHeight ? '100%' : '550px', objectFit: 'cover' }}
              controls
              muted
              autoPlay
              playsInline
              loop={videoFile.loop}
            >
              <source src={video.url} type={`video/${video.extension}`} />
            </video>
          )}
        </Grid>
      </Grid>
    </Grid>
  )
}
