import { Box, Grid, Typography } from '@mui/material'
import { FormattedMessage } from 'react-intl'

import EmailVerificationCodeForm from './EmailVerificationCode.form'
import useStyles from './EmailVerificationCode.styles'

export default function EmailVerificationCode({ userData, goToCreateAccount, setSnackBarMsg, setSnackBarOpen }) {
  const { classes } = useStyles()

  function maskEmail(email) {
    const [localPart, domain] = email.split('@')
    const maskedLocalPart = localPart.slice(0, 2) + '*'.repeat(localPart.length - 2)
    return `${maskedLocalPart}@${domain}`
  }

  return (
    <Box>
      <Grid>
        <Typography variant='h3' className={classes.verificationCodeTitle}>
          <FormattedMessage id={'selfOnboardingPage.landingPage.hero.emailVerificationCodeForm.title'} />
        </Typography>
        <Typography variant='body1' className={classes.verificationCodeSubtitle}>
          <FormattedMessage
            id='selfOnboardingPage.landingPage.hero.emailVerificationCodeForm.subtitle'
            values={{
              br: () => <br></br>,
              email: maskEmail(userData?.email ?? 'jamescuentacolombia@gmail.com')
            }}
          />
        </Typography>
      </Grid>
      <EmailVerificationCodeForm
        userData={userData}
        goToCreateAccount={goToCreateAccount}
        setSnackBarMsg={setSnackBarMsg}
        setSnackBarOpen={setSnackBarOpen}
      />
    </Box>
  )
}
