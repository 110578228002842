export const getTextColor = (hexColor) => {
  const color = hexColor ? hexColor : ' #FAFAFA'

  // Convert the hex color to RGB values
  const r = parseInt(color.substring(1, 3), 16)
  const g = parseInt(color.substring(3, 5), 16)
  const b = parseInt(color.substring(5, 7), 16)

  // Calculate the luminance
  const luminance = (0.299 * r + 0.587 * g + 0.114 * b) / 255

  // Choose the text color based on the luminance
  return luminance > 0.5 ? 'black' : 'white'
}
