import { useState } from 'react'
import { SIGNUP_STEP_CONSTANTS } from '@/constants/SignupConstants'
import useAmplitudeInit from '@/hooks/useAmplitudeInit'
import { Grid } from '@mui/material'
import { GoogleReCaptchaProvider } from 'react-google-recaptcha-v3'
import CreateAccount from './components/create-account/CreateAccount'
import CustomSnackbar from '../custom-snackbar/CustomSnackbar'
import EmailVerificationCode from './components/email-verification-code/EmailVerificationCode'
import PhoneVerificationCode from './components/phone-verification-code/PhoneVerificationCode'

const Signup = ({ positionTitle }) => {
  
  useAmplitudeInit()
  const [snackBarOpen, setSnackBarOpen] = useState(false)
  const [snackBarMsg, setSnackBarMsg] = useState(null)
  const [userData, setUserData] = useState(null)
  const [currentStep, setCurrentStep] = useState(SIGNUP_STEP_CONSTANTS.CREATE_ACCOUNT)

  const goToCreateAccount = () => setCurrentStep(SIGNUP_STEP_CONSTANTS.CREATE_ACCOUNT)
  const goToPhoneVerificationCode = () => setCurrentStep(SIGNUP_STEP_CONSTANTS.PHONE_VERIFICATION_CODE)
  const goToEmailVerificationCode = () => setCurrentStep(SIGNUP_STEP_CONSTANTS.EMAIL_VERIFICATION_CODE)

  const onSubmitSuccess = (userData) => {
    setUserData(userData)
    goToPhoneVerificationCode()
  }

  const signUpSteps = [
    <CreateAccount key={SIGNUP_STEP_CONSTANTS.CREATE_ACCOUNT} onSubmitSuccess={onSubmitSuccess} />,
    <PhoneVerificationCode
      key={SIGNUP_STEP_CONSTANTS.PHONE_VERIFICATION_CODE}
      userData={userData}
      goToCreateAccount={goToCreateAccount}
      goToEmailVerificationCode={goToEmailVerificationCode}
      setSnackBarOpen={setSnackBarOpen}
      setSnackBarMsg={setSnackBarMsg}
      setUserData={setUserData}
    />,
    <EmailVerificationCode
      key={SIGNUP_STEP_CONSTANTS.EMAIL_VERIFICATION_CODE}
      userData={userData}
      goToCreateAccount={goToCreateAccount}
      setSnackBarOpen={setSnackBarOpen}
      setSnackBarMsg={setSnackBarMsg}
    />
  ]

  return (
    <GoogleReCaptchaProvider reCaptchaKey={process.env.NEXT_PUBLIC_RECAPTCHA_KEY}>
      <Grid
        sx={(theme) => ({
          width: '100%',
          display: 'grid',
          gridTemplateColumns: '1fr',
          gridAutoRows: 'max-content',
          gap: '8px',
          borderRadius: '8px',
          background: '#FFF',
          padding: '32px 20px',
          [theme.breakpoints.up('md')]: {
            order: positionTitle === 'right' ? '-1' : 'initial',
            padding: '40px',
            maxWidth: '520px',
            justifySelf: 'flex-end'
          }
        })}
      >
        {signUpSteps[currentStep]}
      </Grid>
      <CustomSnackbar
        open={snackBarOpen}
        message={snackBarMsg}
        variant='error'
        onClose={() => {
          setSnackBarMsg(null)
          setSnackBarOpen(false)
        }}
      />
    </GoogleReCaptchaProvider>
  )
}


export default Signup;