import { makeStyles } from 'tss-react/mui'

export default makeStyles()((theme) => ({
  link: {
    fontSize: 18,
    fontWeight: 600,
    color: 'inherit',
    textDecorationColor: 'inherit'
  }
}))
