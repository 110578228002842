import React from 'react'

const CheckDisabledIcon = () => {
  return (
    <svg xmlns='http://www.w3.org/2000/svg' width='18' height='16' viewBox='0 0 18 16'>
      <g fill='none' fillRule='evenodd'>
        <path
          fill='#9AA9B9'
          d='M9 1.333c-3.667 0-6.667 3-6.667 6.667s3 6.667 6.667 6.667 6.667-3 6.667-6.667-3-6.667-6.667-6.667z'
        ></path>
        <path fill='#FFF' d='M12.356 5L7.541 9.734 5.644 7.869 5 8.502 7.541 11 13 5.633z'></path>
      </g>
    </svg>
  )
}

export default CheckDisabledIcon
