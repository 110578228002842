import { makeStyles } from 'tss-react/mui'

export default makeStyles()(() => ({
  description: {
    color: '#1f2532',
    fontSize: 14,
    margin: '0 0 16px 0'
  },
  rules: {
    display: 'grid',
    gridTemplateColumns: '1fr'
  },
  rule: {
    display: 'grid',
    gridTemplateColumns: 'max-content 1fr',
    alignItems: 'center',
    color: '#9aa9b9',
    fontSize: 12,
    marginBottom: 4,
    '& span': {
      marginLeft: 8
    }
  },
  activated: {
    color: '#15b597'
  }
}))
