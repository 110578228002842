import { TextField } from '@mui/material'

import { sendTagManagerEvent } from '@/utils/tagManager'
import ErrorMessage from '../error-message/ErrorMessage'

export default function Input({ field, form: { touched, errors }, ...props }) {
  const fieldError = errors[field.name]
  const fieldTouched = touched[field.name]

  const handleBlur = (event) => {
    field.onBlur(event)
    if (props.onBlur) props?.onBlur()

    fieldError ? sendTagManagerEvent(`input_error_${event.target.id}`) : null
  }

  return (
    <>
      <TextField
        fullWidth
        variant='filled'
        {...field}
        {...props}
        onBlur={handleBlur}
        error={!!fieldError && !!fieldTouched}
      />
      <ErrorMessage
        show={!!(fieldTouched && fieldError)}
        id={fieldError?.id ? fieldError?.id : fieldError}
        values={fieldError?.values}
      />
    </>
  )
}
