import * as React from 'react'
import { Button, Grid } from '@mui/material'
import urlFor from '@/lib/urlFor'
import Image from 'next/image'
import Text from '../text/Text'
import Signup from '../signup/Signup'
import getUrlFile from '@/lib/getUrlFile'
import CallToAction from '../call-to-action/CallToAction'

export default function Banner({
  text,
  cta,
  ctaV2,
  linkCTA,
  positionTitle,
  fullHeight,
  withSignupForm,
  backgroundColor,
  videoFile,
  image,
  backgroundImage,
  backgroundImageMobile,
  minHeight = '400px',
  hasShadowOverlay = false,
  backgroundPosition = 'top'
}) {
  const backgroundImageMobileUrl = urlFor(backgroundImageMobile)
  const backgroundImageUrl = urlFor(backgroundImage)
  const overlay = hasShadowOverlay ? 'linear-gradient(rgba(0, 0, 0, 0.32), rgba(0, 0, 0, 0.32)),' : ''
  const imageUrl = urlFor(image)
  const video = getUrlFile(videoFile)

  return (
    <Grid
      container
      component='section'
      sx={(theme) => ({
        width: '100%',
        minHeight: fullHeight ? 'calc(100vh - 64px)' : minHeight,
        padding: '40px 24px',
        backgroundColor: backgroundColor ? backgroundColor.hex : 'transparent',
        background: backgroundImageMobileUrl
          ? `${overlay} ${backgroundPosition} / cover no-repeat url(${backgroundImageMobileUrl})`
          : backgroundImageUrl
          ? `${overlay} ${backgroundPosition} / cover no-repeat url(${backgroundImageUrl})`
          : '',
        [theme.breakpoints.up('sm')]: {
          background: backgroundImageUrl
            ? `${overlay} ${backgroundPosition} / cover no-repeat url(${backgroundImageUrl})`
            : ''
        },
        [theme.breakpoints.up('md')]: {
          padding: '60px 50px'
        }
      })}
    >
      <Grid
        maxWidth={1280}
        sx={(theme) => ({
          width: '100%',
          margin: '0 auto',
          display: 'grid',
          gridTemplateColumns: '1fr',
          gridAutoRows: 'max-content',
          alignContent: 'center',
          gap: '20px',
          [theme.breakpoints.up('md')]: {
            gridTemplateColumns: '1fr 1fr',
            justifyContent: 'space-between',
            alignItems: 'center',
            gap: '40px'
          }
        })}
      >
        <Grid display='grid' gap='20px'>
          {text && <Text blockContent={text} />}
          {cta && (
            <Button size='large' href={linkCTA} sx={{ width: 'max-content' }} variant='contained'>
              {cta}
            </Button>
          )}
          <CallToAction {...ctaV2} />
        </Grid>

        {withSignupForm && <Signup positionTitle={positionTitle} />}

        {!withSignupForm && image && imageUrl && (
          <Grid
            item
            sx={(theme) => ({
              position: 'relative',
              width: '100%',
              height: '100%',
              minHeight: '300px',
              [theme.breakpoints.up('md')]: {
                order: positionTitle === 'right' ? '-1' : 'initial',
                minHeight: '550px'
              }
            })}
          >
            <Image src={imageUrl} alt={image.alt} fill style={{ objectFit: image.containSize ? 'contain' : 'cover' }} />
          </Grid>
        )}

        {!withSignupForm && videoFile && video?.url && (
          <Grid display='grid' gap='20px' sx={{ borderRadius: '10px', overflow: 'hidden' }}>
            <video
              title={videoFile.alt}
              width='100%'
              height='auto'
              style={{
                maxHeight: fullHeight ? '100%' : '550px',
                objectFit: 'cover',
                order: positionTitle === 'right' ? '-1' : 'initial'
              }}
              controls
              muted
              autoPlay
              playsInline
              loop={videoFile.loop}
            >
              <source src={video.url} type={`video/${video.extension}`} />
            </video>
          </Grid>
        )}
      </Grid>
    </Grid>
  )
}
