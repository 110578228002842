import { Grid } from '@mui/material'
import TableSimple from '../table-simple/TableSimple'
import Text from '../text/Text'

import useStyles from './SectionTable.styles'

const SectionTable = ({ text, backgroundColor, tables }) => {
  const { classes } = useStyles({
    backgroundColor
  })

  return (
    <Grid component='section' className={classes.container}>
      <Grid container maxWidth={1280} margin={'0 auto'} gap='20px'>
        {text && (
          <Grid container direction='row' justifyContent='left' alignItems='center'>
            <Grid className={classes.containerTitles}>
              <Text blockContent={text} />
            </Grid>
          </Grid>
        )}

        <Grid container>
          {tables?.map((table, i) => (
            <TableSimple key={i} sizeTable={table.sizeTable} />
          ))}
        </Grid>
      </Grid>
    </Grid>
  )
}

export default SectionTable
