import { defineType } from 'sanity'
import { MasterDetailIcon } from '@sanity/icons'
import { backgroundColorField, ctaField, textField } from '@/defaultSchema/fields'

export default defineType({
  type: 'object',
  title: 'Text with logos',
  name: 'sectionGrandPartners',
  fields: [
    textField(),
    backgroundColorField,
    {
      type: 'array',
      name: 'logoParnerts',
      title: 'Logo Partners',
      description: 'Logos in the section',
      of: [
        {
          type: 'object',
          name: 'logos',
          fields: [
            {
              type: 'image',
              name: 'logoPartner',
              title: 'Logo Partner',
              description: '<img>',
              options: {
                hotspot: true
              },
              validation: (Rule) => Rule.required()
            },
            {
              type: 'string',
              name: 'altLogo',
              title: 'Alt Logo',
              hidden: ({ parent }) => !parent?.logoPartner
            },
            {
              name: 'external',
              type: 'url',
              title: 'Url external',
              hidden: ({ parent }) => !parent?.logoPartner
            }
          ]
        }
      ]
    },
    ctaField()
  ],
  preview: {
    select: {
      title: 'backgroundColor.hex'
    },
    prepare({ title }) {
      return {
        title: 'Text with logos',
        subtitle: title,
        icon: MasterDetailIcon
      }
    }
  }
})
