import { Grid, Button } from '@mui/material'

import useStyles from './SectionCards.styles'
import CardImageTop from '../card-image-top/CardImageTop'
import { SECTION_POSITION } from '../constants/CardsConstants'
import Text from '../text/Text'
import CallToAction from '../call-to-action/CallToAction'

const SectionCards = ({ text, backgroundColor, positionTitle, cards, cta }) => {
  const position = SECTION_POSITION[positionTitle]

  const { classes } = useStyles({
    backgroundColor: backgroundColor,
    position: position?.display
  })

  return (
    <Grid component='section' className={classes.container}>
      <Grid maxWidth={1280} margin={'0 auto'} className={positionTitle != 'top' ? classes.grids : null}>
        {(text || cta?.label) && (
          <Grid
            container
            md={position?.container}
            direction='row'
            justifyContent='center'
            alignItems='center'
            order={{ md: position?.orderContainer }}
          >
            {text && (
              <Grid className={classes.containerTitles}>
                <Text blockContent={text} />
              </Grid>
            )}

            {cta && cta?.label && positionTitle != 'top' && <CallToAction {...cta} />}
          </Grid>
        )}

        <Grid
          container
          md={position?.containerCard}
          justifyContent='center'
          spacing={3}
          order={{ md: position?.orderCard }}
        >
          {cards?.map((card, i) => (
            <CardImageTop
              key={i}
              image={card.imageCard}
              text={card.text}
              cta={card.cta}
              icon={card.icon}
              withShadow={card.withShadow}
              backgroundColor={card.backgroundColor}
              borderColor={card.borderColor}
            />
          ))}
        </Grid>

        {cta && cta?.label && positionTitle == 'top' && (
          <Grid container direction='row' justifyContent='center' alignItems='center' className={classes.cta}>
            <CallToAction {...cta} />
          </Grid>
        )}
      </Grid>
    </Grid>
  )
}

export default SectionCards
