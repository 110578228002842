import axios from 'axios'
import urls from '../constants/Urls'
import UrlUtilities from '../utils/UrlUtilities'

class BaseService {
  constructor() {
    this.urls = urls
  }

  getServiceInstance() {
    if (!this.service) {
      this.service = axios.create({
        headers: {
          'X-Auth': 'Bearer ' + localStorage.getItem('id_token')
        }
      })
    }
    return this.service
  }

  getServiceInstanceAuthorization() {
    if (!this.serviceAuthorization) {
      this.serviceAuthorization = axios.create({
        headers: {
          Authorization: `Bearer ${localStorage.getItem('id_token')}`
        }
      })
    }
    delete this.serviceAuthorization.defaults.headers.common['X-Auth']
    return this.serviceAuthorization
  }

  getServiceInstanceBase64(encodedData) {
    if (!this.service) {
      this.service = axios.create({
        headers: {
          'x-token': encodedData
        }
      })
    }
    return this.service
  }

  getServiceInstanceBasicAuthorization() {
    if (!this.service) {
      this.service = axios.create({
        headers: {
          Authorization: '1234'
        }
      })
    }
    return this.service
  }

  getUrlParams(params) {
    return UrlUtilities.generateQueryString(params)
  }

  replaceUrlParams(url, params) {
    let newUrl = url.slice(0)

    Object.keys(params).map(function (param) {
      const value = params[param] !== null ? params[param] : ''
      newUrl = newUrl.replace(':' + param, value)
      return null
    })

    return newUrl
  }

  getUrlPath(url) {
    return url && url.indexOf('?') >= 0 ? `${url.split('?')[0]}?` : url
  }

  getUrlQueryParamAsArray(url) {
    if (url.indexOf('?') < 0) {
      return []
    }
    const urlQuery = url.split('?')[1]

    return urlQuery.split('&').map((param) => param.split('=')[1])
  }
}

export default BaseService
