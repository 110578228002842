import {
  ARGENTINIAN_FLAG_IMG,
  BRAZILIAN_FLAG_IMG,
  CHILEAN_FLAG_IMG,
  COLOMBIAN_FLAG_IMG,
  COSTARICAN_FLAG_IMG,
  ECUADORIAN_FLAG_IMG,
  MEXICAN_FLAG_IMG,
  PERUVIAN_FLAG_IMG,
  URUGUAYAN_FLAG_IMG
} from '../constants/Images'

export const PHONE_LENGHTS = {
  AR: { min: 11, max: 13 },
  BR: { min: 10, max: 11 },
  CL: { min: 9, max: 9 },
  CO: { min: 10, max: 10 },
  CR: { min: 8, max: 8 },
  EC: { min: 10, max: 10 },
  MX: { min: 10, max: 10 },
  PE: { min: 9, max: 9 },
  UY: { min: 8, max: 8 }
}

export const MASK_PHONE_BY_COUNTRY = {
  AR: '\\9999999999999',
  BR: '(99) 999999999',
  CL: '9 9999-9999',
  CO: '(999) 9999999',
  CR: '9999-9999',
  EC: '0 99-999-9999',
  MX: '(99) 9999-9999',
  PE: '999 999 999',
  UY: '99 999 999'
}

export const phonePrefixes = [
  { country: 'AR', label: 'Argentina', value: '+54', icon: ARGENTINIAN_FLAG_IMG },
  { country: 'BR', label: 'Brasil', value: '+55', icon: BRAZILIAN_FLAG_IMG },
  { country: 'CL', label: 'Chile', value: '+56', icon: CHILEAN_FLAG_IMG },
  { country: 'CO', label: 'Colombia', value: '+57', icon: COLOMBIAN_FLAG_IMG },
  { country: 'CR', label: 'Costa Rica', value: '+506', icon: COSTARICAN_FLAG_IMG },
  { country: 'EC', label: 'Ecuador', value: '+593', icon: ECUADORIAN_FLAG_IMG },
  { country: 'MX', label: 'México', value: '+52', icon: MEXICAN_FLAG_IMG },
  { country: 'PE', label: 'Perú', value: '+51', icon: PERUVIAN_FLAG_IMG },
  { country: 'UY', label: 'Uruguay', value: '+598', icon: URUGUAYAN_FLAG_IMG }
]
