import { defineType } from 'sanity'
import { MasterDetailIcon } from '@sanity/icons'
import { backgroundColorField, borderColorField, ctaField, imageField, textField } from '@/defaultSchema/fields'

export default defineType({
  type: 'object',
  title: 'Cards',
  name: 'sectionCards',
  fields: [
    textField(),
    {
      type: 'string',
      name: 'positionTitle',
      title: 'Position title',
      initialValue: 'top',
      options: {
        list: [
          { title: 'Top', value: 'top' },
          { title: 'Left', value: 'left' },
          { title: 'Right', value: 'right' }
        ]
      },
      description: 'The position title & subtitle',
      validation: (Rule) => Rule.required()
    },
    backgroundColorField,
    {
      type: 'array',
      name: 'cards',
      title: 'Cards',
      description: 'Cards in the section',
      validation: (Rule) => Rule.required().min(1),
      of: [
        {
          type: 'object',
          name: 'card',
          fields: [
            imageField({ name: 'imageCard' }),
            {
              type: 'boolean',
              name: 'icon',
              title: 'Icon',
              description: 'Select true if the image field is an icon',
              initialValue: false,
              hidden: ({ parent }) => !parent?.imageCard
            },
            {
              type: 'boolean',
              name: 'withShadow',
              title: 'Shadow',
              description: 'Select true to add shadow to the card',
              initialValue: false
            },
            backgroundColorField,
            borderColorField,
            textField(),
            ctaField()
          ],
          preview: {
            select: {
              title: 'imageCard.alt',
              media: 'imageCard'
            },
            prepare({ title, media }) {
              return {
                title: title,
                media: media
              }
            }
          }
        }
      ]
    },
    ctaField()
  ],
  preview: {
    select: {},
    prepare({}) {
      return {
        title: 'Cards',
        icon: MasterDetailIcon
      }
    }
  }
})
