import { Box, Grid, Typography } from '@mui/material'
import { FormattedMessage } from 'react-intl'

import PhoneVerificationCodeForm from './PhoneVerificationCode.form'
import useStyles from './PhoneVerificationCode.styles'

export default function PhoneVerificationCode({
  userData,
  goToCreateAccount,
  goToEmailVerificationCode,
  setSnackBarMsg,
  setSnackBarOpen,
  setUserData
}) {
  const { classes } = useStyles()

  return (
    <Box>
      <Grid>
        <Typography variant='h3' className={classes.verificationCodeTitle}>
          <FormattedMessage id={'selfOnboardingPage.landingPage.hero.phoneVerificationCodeForm.title'} />
        </Typography>
        <Typography variant='body1' className={classes.verificationCodeSubtitle}>
          <FormattedMessage
            id='selfOnboardingPage.landingPage.hero.phoneVerificationCodeForm.subtitle'
            values={{
              br: () => <br></br>,
              prefixPhone: userData?.phonePrefix,
              lastTwoDigits: `${userData?.phone?.slice(-2)?.padStart(userData?.phone.length, '*')}`
            }}
          />
        </Typography>
      </Grid>
      <PhoneVerificationCodeForm
        userData={userData}
        goToCreateAccount={goToCreateAccount}
        goToEmailVerificationCode={goToEmailVerificationCode}
        setSnackBarMsg={setSnackBarMsg}
        setSnackBarOpen={setSnackBarOpen}
        setUserData={setUserData}
      />
    </Box>
  )
}
