import * as React from 'react'
import Image from 'next/image'
import ErrorMessage from '../error-message/ErrorMessage'
import { Select, MenuItem, InputLabel, FormControl } from '@mui/material'
import { PHONE_PREFIX } from '@/constants/FieldNames'

export default function SelfOnboardingSelect({ field, form: { touched, errors }, ...props }) {
  const fieldError = errors[field.name]
  const fieldTouched = touched[field.name]
  const fieldNameOption = [PHONE_PREFIX]

  return (
    <FormControl fullWidth>
      {props.label && (
        <InputLabel id={field.name} disabled={props.disabled} variant='filled'>
          {props.label}
        </InputLabel>
      )}
      <Select displayEmpty variant='filled' labelId={field.name} {...field} {...props}>
        {props.options.map((option) => (
          <MenuItem key={option.value} value={option.value} id={props.id}>
            {!!option.flag && (
              <Image width={28} height={20} src={option.flag} alt={option.name} className={classes.itemImage} />
            )}
            {fieldNameOption.includes(field.name) ? `${option.label} (${option.prefix})` : option.label}
          </MenuItem>
        ))}
      </Select>
      <ErrorMessage show={!!(fieldTouched && fieldError)} id={fieldError} />
    </FormControl>
  )
}
