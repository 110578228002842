import { makeStyles } from 'tss-react/mui'

export default makeStyles()((theme, { backgroundColor, borderColor, withShadow, contain }) => ({
  card: {
    maxHeight: '100%',
    borderRadius: 8,
    boxShadow: withShadow ? '0px 10px 30px #0000001a' : 'none',
    backgroundColor: backgroundColor?.hex || 'transparent',
    border: borderColor ? `1px solid ${borderColor.hex}` : 'unset'
  },
  link: {
    padding: 20,
    color: '#0ec665',
    fontWeight: 'bold',
    textDecorationColor: '#0ec665',
    textAlign: 'center'
  },
  iconImage: {
    padding: 25
  },
  imgCard: {
    backgroundSize: contain ? 'contain' : 'cover'
  }
}))
