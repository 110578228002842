import TagManager from 'react-gtm-module'

export const sendTagManager = (event, eventProps) => {
  const tagManagerArgs = {
    dataLayer: {
      event: event,
      eventProps: { ...eventProps }
    }
  }
  TagManager.dataLayer(tagManagerArgs)
}

export const sendTagManagerHotjar = (event, hotjarTag) => {
  const tagManagerArgs = {
    dataLayer: {
      event: event,
      hotjarTags: [hotjarTag]
    }
  }
  TagManager.dataLayer(tagManagerArgs)
}

export const sendTagManagerEvent = (event) => {
  const tagManagerArgs = {
    dataLayer: {
      event: event
    }
  }
  TagManager.dataLayer(tagManagerArgs)
}
