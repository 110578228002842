import { defineType } from 'sanity'
import { ThListIcon } from '@sanity/icons'
import { backgroundColorField, textField } from '@/defaultSchema/fields'

export default defineType({
  type: 'object',
  title: 'Table',
  name: 'sectionTable',
  fields: [
    textField(),
    backgroundColorField,
    {
      type: 'array',
      name: 'tables',
      title: 'Tables',
      description: 'Tables in the section',
      validation: (Rule) => Rule.required(),
      of: [
        {
          type: 'object',
          name: 'tables',
          validation: (Rule) => Rule.required(),
          fields: [
            {
              type: 'table',
              name: 'sizeTable',
              title: 'Size table',
              description: 'Create columns and rows'
            }
          ],
          preview: {
            prepare() {
              return {
                title: 'Table simple',
                subtitle: '#',
                icon: ThListIcon
              }
            }
          }
        }
      ]
    }
  ],
  preview: {
    select: {
      title: 'title'
    },
    prepare({ title }) {
      return {
        title: 'Table',
        subtitle: title || '#',
        icon: ThListIcon
      }
    }
  }
})
