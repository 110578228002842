import Banner from '@/components/banner/Banner'
import BannerSchema from '@/components/banner/Banner.schema'
import Faqs from '@/components/faqs/Faqs'
import FaqsSchema from '@/components/faqs/Faqs.schema'
import SectionCards from '@/components/section-cards/SectionCards'
import SectionCardsSchema from '@/components/section-cards/SectionCards.schema'
import SectionCardsTestimonial from '@/components/section-cards-testimonial/SectionCardsTestimonial'
import SectionCardsTestimonialSchema from '@/components/section-cards-testimonial/SectionCardsTestimonial.schema'
import SectionGranPartners from '@/components/section-grand-partners/SectionGrandPartners'
import SectionGrandPartnersSchema from '@/components/section-grand-partners/SectionGrandPartners.schema'
import SectionTable from '@/components/section-table/SectionTable'
import SectionTableSchema from '@/components/section-table/SectionTable.schema'
import SectionVideo from '@/components/section-video/Video'
import SectionVideoSchema from '@/components/section-video/Video.schema'

export const SectionComponents = {
  banner: { component: Banner, schema: BannerSchema },
  faqs: { component: Faqs, schema: FaqsSchema },
  sectionCards: { component: SectionCards, schema: SectionCardsSchema },
  sectionVideo: { component: SectionVideo, schema: SectionVideoSchema },
  sectionTable: { component: SectionTable, schema: SectionTableSchema },
  sectionCardsTestimonial: { component: SectionCardsTestimonial, schema: SectionCardsTestimonialSchema },
  sectionGrandPartners: { component: SectionGranPartners, schema: SectionGrandPartnersSchema }
}
