import React, { useState, useEffect } from 'react'
import { useIntl } from 'react-intl'
import { TextField } from '@mui/material'
import { useDebounce } from '@uidotdev/usehooks'
import SelfOnboardingService from '@/services/SelfOnboardingService'
import { amplitude } from '@/hooks/useAmplitudeInit'
import { SOB_INSERT_REFERRAL_CODE } from '@/constants/EventsAmplitudeConstatns'

const selfOnboardingService = new SelfOnboardingService()

const ReferralCodeInput = ({
  field,
  country,
  referralCodeQuery,
  setReferralCode,
  setIsReferralCodeValid,
  ...props
}) => {
  const [inputValue, setInputValue] = useState(referralCodeQuery ?? '')
  const [errorState, setErrorState] = useState(false)
  const [helperText, setHelperText] = useState('')
  const debouncedValue = useDebounce(inputValue, 800)

  const { formatMessage } = useIntl()

  useEffect(() => {
    const codeValue = inputValue.toLocaleUpperCase()
    if (debouncedValue) {
      changeReferralCode(codeValue)
    }
  }, [debouncedValue])

  const changeReferralCode = async (codeValue) => {
    const { isValid, referralCode } = await selfOnboardingService.validateReferralCode({
      referralCode: codeValue,
      country
    })

    if (isValid) {
      setReferralCode(referralCode.toLocaleUpperCase())
      setErrorState(false)
      setHelperText('')
      amplitude.track(SOB_INSERT_REFERRAL_CODE, {
        RESPONSE: 'SUCCESS',
        REFERRAL_CODE: referralCode.toLocaleUpperCase()
      })
    } else {
      setErrorState(true)
      setHelperText(
        formatMessage({ id: `selfOnboardingPage.landingPage.hero.createAccountForm.field.referralCode.error` })
      )
      amplitude.track(SOB_INSERT_REFERRAL_CODE, {
        RESPONSE: 'ERROR',
        REFERRAL_CODE: codeValue.toLocaleUpperCase()
      })
    }

    setIsReferralCodeValid(isValid)
  }

  const handleChange = (event) => {
    setIsReferralCodeValid(false)
    setInputValue(event.target.value)
  }

  return (
    <TextField
      fullWidth
      variant='filled'
      {...field}
      {...props}
      value={inputValue}
      onChange={handleChange}
      error={errorState}
      helperText={helperText}
    />
  )
}

export default ReferralCodeInput
