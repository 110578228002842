import Auth0 from 'auth0-js';

import { AUTH_CONFIG } from './auth0-variables';

export default class Auth {
  InitEmbeddedLogin = (email, password, encryptDataUser) => {
    const webAuth = new Auth0.WebAuth({
      domain: AUTH_CONFIG.domain,
      cookieDomain: AUTH_CONFIG.cookieDomain,
      clientID: AUTH_CONFIG.clientId,
      audience: AUTH_CONFIG.audience,
      responseType: 'token id_token',
      redirectUri: AUTH_CONFIG.callbackUrl+"?user="+encodeURIComponent(encryptDataUser),
      scope: 'openid profile email',
    });

    webAuth.login({
      realm: process.env.NEXT_PUBLIC_AUTH0_REALM,
      username: email,
      password,
    }, (err, authResult) => {
    });
  };
}
