import { Button } from '@mui/material'

export default function CallToAction({ label, link, target, variant }) {
  if (!label) return null
  return (
    <Button
      size='large'
      sx={{ width: 'max-content' }}
      href={link}
      target={target ?? '_self'}
      variant={variant ?? 'contained'}
    >
      {label}
    </Button>
  )
}
