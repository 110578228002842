import * as Sentry from '@sentry/react'

export const captureError = (err, msg, data, user) => {
  Sentry.withScope((scope) => {
    if (user) scope.setUser(user)
    if (data?.country) scope.setTag('country', data.country)
    if (data) scope.setExtra('data', JSON.stringify(data))
    if (msg) scope.setExtra('messageError', msg)

    scope.setLevel('error')
    Sentry.captureException(new Error(err))
  })
}
