import { KeyboardArrowDownRounded } from '@mui/icons-material'
import { Accordion, AccordionDetails, AccordionSummary, Button, Grid, Typography } from '@mui/material'
import Text from '../text/Text'
import CallToAction from '../call-to-action/CallToAction'

export default function Faqs({ text, backgroundColor, faqs }) {
  return (
    <Grid
      component='section'
      sx={(theme) => ({
        padding: '60px 24px',
        background: backgroundColor ? backgroundColor?.hex : '#FAFAFA',
        [theme.breakpoints.up('md')]: {
          padding: '60px 50px'
        }
      })}
    >
      <Grid
        container
        sx={(theme) => ({
          width: '100%',
          maxWidth: 1280,
          margin: '0 auto',
          display: 'grid',
          gridTemplateColumns: '1fr',
          gap: '40px',
          [theme.breakpoints.up('md')]: {
            justifyContent: 'space-between',
            alignItems: 'center'
          }
        })}
      >
        {text && (
          <Grid container display='grid' gridTemplateColumns='1fr' gap='20px'>
            <Text blockContent={text} />
          </Grid>
        )}

        <Grid component='article' container sx={{ display: 'grid', gridTemplateColumns: '1fr' }}>
          {faqs &&
            faqs.map((faq) => {
              return (
                <Grid key={faq._key} sx={{ width: '100%' }}>
                  <Accordion
                    sx={{
                      display: 'grid',
                      gridTemplateColumns: '1fr',
                      width: '100%',
                      padding: '20px 0px',
                      boxShadow: 'none',
                      borderBottom: '1px solid #e6e6e6',
                      '&:before': {
                        opacity: '0 !important'
                      },
                      '&.Mui-expanded': {
                        margin: '0 !important',
                        padding: '20px 0 !important'
                      }
                    }}
                  >
                    <AccordionSummary
                      aria-controls={faq.key}
                      id={faq.key}
                      expandIcon={<KeyboardArrowDownRounded htmlColor='#FF4940' />}
                    >
                      <Typography variant='h4' sx={{ fontSize: 18, fontWeight: 500, color: '#000' }}>
                        {faq.question}
                      </Typography>
                    </AccordionSummary>
                    <AccordionDetails sx={{ fontSize: 16, color: '#000', display: 'grid', gap: '20px' }}>
                      <Typography variant='body1'>{faq.answer}</Typography>
                      <CallToAction {...faq.cta} />
                    </AccordionDetails>
                  </Accordion>
                </Grid>
              )
            })}
        </Grid>
      </Grid>
    </Grid>
  )
}
